<template>
  <div class="bg-white p-10">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="10">
          <a-col :md="6" :sm="24">
            <a-form-item label="商品名称">
              <a-input v-model="queryParams.keyword" placeholder="请输入商品名称" size="small" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="类目">
              <a-cascader
                v-model="queryParams.productCategoryId"
                :fieldNames="{ label: 'name', value: 'id', children: 'childrens' }"
                :options="options"
                changeOnSelect
                expand-trigger="hover"
                placeholder="请选择类目"
                size="small"
                @change="onChangeCascader"
              />
            </a-form-item>
          </a-col>
          <a-button class="mr-5" size="small" type="primary" @click="chaxun()">查询</a-button>
          <a-button class="mr-5" size="small" type="info" @click=";(queryParams = {}), getList()">重置</a-button>
        </a-row>
      </a-form>
    </div>
    <a-spin :spinning="loading" tip="Loading...">
      <div v-if="goods.length === 0">
        <!--        <span style="margin-left: 40px;">暂无数据</span>-->
      </div>
      <div class="goods">
        <a-card
          v-for="(item, index) in goods"
          :key="index"
          :hoverable="true"
          class="goodsCard"
          @click.stop="onFn(item)"
        >
          <img slot="cover" :src="item.imgUrl" alt="example" />
          <a-tooltip :title="item.name" placement="top">
            <a-card-meta :title="item.name">
              <template slot="description">
                <div class="card flex-row flex-row-between">
                  <span v-if="item.tradePrice" style="color: #d11514; font-size: 16px; font-weight: 600"
                    >￥{{ item.tradePrice.toFixed(2) }}</span
                  >
                </div>
              </template>
            </a-card-meta>
          </a-tooltip>
          <div class="btn">
            <div v-if="item.isPuint == 0" class="right" @click.stop="handleAddGoods(item)">一键入库</div>
            <div v-else class="right">已入库</div>
          </div>
        </a-card>
      </div>
      <div v-if="goods.length !== 0" class="flex-row" style="flex-direction: row-reverse; padding: 10px">
        <a-pagination
          v-model="queryParam.current"
          :page-size="queryParam.size"
          :page-size-options="pageSizeOptions"
          :total="total"
          show-size-changer
          @change="onChange"
          @showSizeChange="onShowSizeChange"
        >
        </a-pagination>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { jdgoodsList, jdgoodsTree } from '@/api/MengDa'

export default {
  name: 'MengDa',
  data() {
    return {
      loading: true, // 加载Boole
      options: [], // 分类类目的数据
      goods: [], // 列表数据
      pageSizeOptions: ['10', '20', '30'], // 分页-指定每页可以显示多少条
      total: 1, // 分页-数据总数
      queryParam: {
        current: 1,
        size: 30,
        descs: 'update_time',
      },
      queryParams: {},
      firstCategoryId: '',
      secondCategoryId: '',
      thirdCategoryId: '',
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'infoProfect']),
  },
  created() {
    this.getList()
    this.initTree()
  },
  methods: {
    onChangeCascader(e) {
      this.firstCategoryId = e[0]
      this.secondCategoryId = e[1]
      this.thirdCategoryId = e[2]
    },
    /** 详情 */
    onFn(item) {
      let PAGE_URL = window.origin + '/commodity/' + item.id
      window.open(PAGE_URL, '_blank')
    },
    /** 初始化查询列表 */
    getList() {
      this.loading = true
      jdgoodsList(this.queryParam)
        .then((res) => {
          console.log('列表', res)
          this.loading = false
          this.goods = res.data.records
          this.total = res.data.total
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
        })
    },
    /** 初始化分类查询 */
    initTree() {
      jdgoodsTree()
        .then((res) => {
          this.options = res.data
        })
        .catch((err) => {
          console.error(err)
          this.$message.error('数据获取失败')
        })
    },
    /** 入库 */
    handleAddGoods(item, index) {
      const that = this
      const ids = [item.id ? item.id : null]
      this.$AddGroupModal({
        ids,
        md: true,
        handleOk: (val) => {
          if (val === 'ok') {
            that.getList()
          }
        },
      })
    },
    /** 查询 */
    chaxun() {
      this.loading = true
      let objc = {
        productType: 8,
        firstCategoryId: this.firstCategoryId,
        secondCategoryId: this.secondCategoryId,
        thirdCategoryId: this.thirdCategoryId,
        searchName: this.queryParams.keyword,
      }

      jdgoodsList(objc)
        .then((res) => {
          this.loading = false
          this.goods = res.data.records || []
          this.total = res.data.total
        })
        .catch((err) => {
          this.loading = false
        })
    },
    /** 分页 */
    onChange(page, pageSize) {
      let obj = { productCategoryId: null }
      if (this.queryParams.productCategoryId) {
        obj.productCategoryId = this.queryParams.productCategoryId[this.queryParams.productCategoryId.length - 1]
      }
      this.queryParam.current = page
      this.queryParam.size = pageSize
      this.loading = true
      let objInfo = Object.assign({}, this.queryParam, this.queryParams, obj)
      jdgoodsList(objInfo).then((res) => {
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
      })
    },
    /** 分页 */
    onShowSizeChange(pageIndex, pageSize) {
      let obj = { productCategoryId: null }
      if (this.queryParams.productCategoryId) {
        obj.productCategoryId = this.queryParams.productCategoryId[this.queryParams.productCategoryId.length - 1]
      }
      this.queryParam.current = 1
      this.queryParam.size = pageSize
      this.loading = true
      let objInfo = Object.assign({}, this.queryParam, this.queryParams, obj)
      jdgoodsList(objInfo).then((res) => {
        this.loading = false
        this.goods = res.data.records
        this.total = res.data.total
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.goods {
  padding: 0 20px 20px 0;
  display: flex;
  flex-wrap: wrap;
  .goodsCard {
    position: relative;
    margin: 10px 20px;
    width: 250px;
    height: 370px;
    box-shadow: 3.4px 3.4px 2.7px rgba(0, 0, 0, 0.016), 8.7px 8.7px 6.9px rgba(0, 0, 0, 0.025),
      17.7px 17.7px 14.2px rgba(0, 0, 0, 0.03), 36.5px 36.5px 29.2px rgba(0, 0, 0, 0.034),
      100px 100px 80px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    img {
      width: 238px;
      height: 238px;
      padding: 10px 0 0 10px;
      border-radius: 10px;
      object-fit: cover;
    }

    .btn {
      position: absolute;
      padding: 0 15px;
      width: 250px;
      left: 0;
      right: 0;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      color: #fff;
      cursor: pointer;
      // border: 1px solid red;
      .left,
      .right {
        width: 100px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50px;
        background-color: $color-main;
        color: #fff;
      }
    }
  }
}
</style>
